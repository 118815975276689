import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CardBody, Flex, Icon, Text } from '@chakra-ui/react'
import { Channel } from '@Types/channel/Channel'
import { useFormat } from 'helpers/hooks/useFormat'
import { AiFillCloseCircle, AiFillCheckCircle } from 'react-icons/ai'
import { IoCallOutline, IoLocationOutline, IoTimeOutline } from 'react-icons/io5'
import { useStore } from 'frontastic/contexts/storeContext'
import { storeDetailsBox, cardBodyText, cardBorder } from './constants'
import StoreTiming from './store-timing'
import { phoneMask } from './utils'

export interface StoreListItemProps {
  storeData: Channel
  idx?: number
  distance: number
  storeHeading?: {}
  selectedStoreOnly?: boolean
  handleSelectionOfNewStore: any
  variantCountForBOPISInCartOnly?: number
  availableQtyOfSelectedVariant?: boolean
}

export const StoreListItem = ({
  storeData,
  idx,
  distance = 0,
  storeHeading,
  selectedStoreOnly,
  handleSelectionOfNewStore,
  variantCountForBOPISInCartOnly,
  availableQtyOfSelectedVariant,
}: StoreListItemProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { isPDPPage, isInStorePickUp } = useStore()
  const [storeAvailability, setStoreAvailability] = useState(false)

  const productStockMessage = () => {
    const stockMessage = () => {
      if (!isPDPPage) {
        return null // Do not render if it's not a PDP page
      }

      if (storeData?.inventoryMessage === 'AVAILABLE') {
        return (
          <Box display={'flex'} alignItems={'center'} gap={'var(--sizes-1, 4px)'}>
            <AiFillCheckCircle width={'16px'} height={'16px'} />
            <Text tabIndex={0} color="shading.600" fontSize={'14px'} fontWeight={'400'}>
              {formatMessage({
                id:
                  variantCountForBOPISInCartOnly > 0 && availableQtyOfSelectedVariant
                    ? 'product.store.available.only.few.left'
                    : 'product.store.available',
              })}
            </Text>
          </Box>
        )
      } else {
        return (
          <Box display={'flex'} alignItems={'center'} gap={'var(--sizes-1, 4px)'}>
            <AiFillCloseCircle width={'16px'} height={'16px'} />
            <Text tabIndex={0} color="shading.600" fontSize={'14px'} fontWeight={'400'}>
              {formatMessage({ id: 'product.store.outofstock' })}
            </Text>
          </Box>
        )
      }
    }
    return stockMessage()
  }

  const handelStore = (store) => {
    handleSelectionOfNewStore(store)
  }

  useEffect(() => {
    setStoreAvailability(storeData?.inventoryMessage !== 'AVAILABLE')
  }, [storeData])

  // stockMessage dynamically changes based on isPDPPage and currentPDPVariant
  // Memoizing stockMessage to avoid unnecessary recalculations
  // Memoize based on these dependencies

  return (
    <Card
      style={cardBorder}
      mb={'12px'}
      border={selectedStoreOnly ? '2px !important' : ''}
      borderColor={selectedStoreOnly ? '#000' : ''}
      mt={selectedStoreOnly ? '8px' : '0'}
    >
      <CardBody
        gap={'8px'}
        display={'flex'}
        alignItems={'flex-start'}
        flexDirection={'column'}
        p={{ base: '20px' }}
        style={cardBodyText}
        width={'100%'}
        color={'text.primary'}
      >
        <Flex justifyContent={'space-between'} alignItems={'first baseline'} width={'100%'}>
          <Box tabIndex={0} display={'flex'} alignItems={'first baseline'}>
            <Text style={storeHeading} color={'text.primary'}>
              {storeData?.address?.city}
            </Text>
            <Text ml={1} fontSize={'14px'} color={'#76797E'}>
              ({storeData?.custom?.fields?.lastname})
            </Text>
          </Box>
          <Text tabIndex={0} fontSize={'14px'} color={'#76797E'}>
            {`${distance} mi`}
          </Text>
        </Flex>

        {productStockMessage()}

        <Flex mt={2} maxW={'90%'} width={'100%'}>
          <Box pr={2} width={'100%'}>
            <Flex mb={'12px'} style={storeDetailsBox}>
              <Box minWidth={'16px'}>
                <Icon as={IoLocationOutline} fontSize={17} />
              </Box>
              <Text tabIndex={0}>
                {`${storeData?.address?.streetName}, ${storeData?.address?.city}, ${storeData?.address?.state},  ${storeData?.address?.postalCode}`}
              </Text>
            </Flex>
            <Flex mb={'12px'} style={storeDetailsBox}>
              <Box minWidth={'16px'}>
                <Icon as={IoCallOutline} fontSize={17} />
              </Box>
              <Box>
                <Text tabIndex={0}>{phoneMask(storeData?.address?.phone)}</Text>
              </Box>
            </Flex>
            <Flex gap={'12px'} mb={!selectedStoreOnly ? '16px' : '0'}>
              <Box minWidth={'16px'}>
                <Icon as={IoTimeOutline} fontSize={17} />
              </Box>
              <Box w={'100%'}>
                <StoreTiming key={idx} storeTiming={storeData?.custom?.fields?.storeHours} index={idx}></StoreTiming>
              </Box>
            </Flex>
            {!selectedStoreOnly && (
              <Button
                type="submit"
                bgColor="#000"
                onClick={() => {
                  handelStore(storeData)
                }}
                p={'10px 8px'}
                fontSize={'12px'}
                height={'auto'}
                isDisabled={isInStorePickUp ? isPDPPage && storeAvailability : false}
              >
                {formatMessage({ id: 'mystore.set.mystore' })}
              </Button>
            )}
          </Box>
        </Flex>
      </CardBody>
    </Card>
  )
}
