/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useDisclosure, Text, Icon, HStack, useBreakpointValue, Button } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { useWatchAndUpdateSelectedStoreData } from 'helpers/hooks/useWatchAndUpdateSelectedStoreData'
import { IoLocationOutline } from 'react-icons/io5'
import { useStore } from 'frontastic/contexts/storeContext'
import { usePdp } from 'frontastic/provider/frontastic'
import { StoreFinderDrawer } from './store-finder-drawer'
import { StoreFinderPopup } from './store-finder-popup'
import { MyStoreProps } from './types'

export const refMyStore = React.createRef<HTMLButtonElement>()

export const MyStore = ({ viewType, showMyStoreTitle, shippingType, callBack }: MyStoreProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const { formatMessage } = useFormat({ name: 'common' })
  const { getStore } = usePdp()
  const {
    selectedStoreData,
    setNearByStoreListDataForFindStores,
    setIsInStorePickUp,
    guestLocationCookie,
    setSelectedStoreData,
    setSelectedStoreListData,
  } = useStore()

  const fetchNearByStores = async (payload) => {
    try {
      const res = await getStore(payload)
      const storesResult = Array.isArray(res) ? res : []
      if (storesResult?.length > 0) {
        setSelectedStoreData(storesResult[0])
        setSelectedStoreListData(storesResult)
        setNearByStoreListDataForFindStores(storesResult)
      }
    } catch (error) {
      console.error('Error fetching stores:', error)
    }
  }

  useEffect(() => {
    if (!selectedStoreData?.address?.city && guestLocationCookie) {
      const [lat, lng] = guestLocationCookie?.split('|')?.slice(1, 3)
      const payload = { lat, lng, edp: '', radius: '25' }
      fetchNearByStores(payload)
    }
  }, [guestLocationCookie])

  useWatchAndUpdateSelectedStoreData()

  const onCloseHandel = (obj) => {
    if (callBack) {
      callBack(obj)
    }
    /*
     Setting this to [] because the store drawer was closed and
     we do not want to retain older store list in the store drawer
    */
    setNearByStoreListDataForFindStores([])
    setIsInStorePickUp(false)
    return onClose()
  }

  const onClickOfMyStore = () => {
    setIsInStorePickUp(false)
    onOpen()
  }

  return (
    <>
      {showMyStoreTitle && (
        <HStack
          maxW="container.2xl"
          m="auto"
          px={{ base: 0, lg: 0 }}
          py={0}
          spacing={0}
          bg="brand.muted"
          justifyContent={isMobile ? 'space-between' : ' '}
        >
          <Button onClick={onClickOfMyStore} ref={refMyStore} variant="link" _hover={{ textDecoration: 'none' }}>
            <HStack spacing={1}>
              <Icon as={IoLocationOutline} boxSize={4} color="black" />
              <Text color="black" display={{ base: 'none', md: 'block', lg: 'block' }} textStyle="body-100">
                {formatMessage({ id: 'megamenu.details.myStore' })}
              </Text>
              <Text
                mt={'1px'}
                textStyle={{ base: 'heading-mobile-75', lg: 'heading-desktop-75' }}
                color={selectedStoreData?.address?.city !== '' ? 'text.success' : 'black'}
              >
                {selectedStoreData?.address?.city === '' ? 'Set Location' : selectedStoreData?.address?.city}
              </Text>
            </HStack>
          </Button>
        </HStack>
      )}

      {viewType == 'drawer' && <StoreFinderDrawer isOpen={isOpen} onClose={onCloseHandel} finalFocus={refMyStore} />}

      {viewType == 'popup' && (
        <StoreFinderPopup shippingType={shippingType} isOpen={isOpen} onClose={onClose}></StoreFinderPopup>
      )}
    </>
  )
}
