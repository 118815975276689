import React, { useState } from 'react'
import {
  Box,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  Divider,
  Flex,
  Text,
  Center,
  Container,
} from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { useStore } from 'frontastic/contexts/storeContext'
import { storeHeading } from './constants'
import { StoreList } from './store-list'
import { StoreSearch } from './store-search'

interface StoreFinderDrawerProps {
  isOpen: any
  onClose: any
  finalFocus?: any
  nearByStoreListDataForFindStores?: any
  edpValue?: any
}

export const StoreFinderDrawer = (props: StoreFinderDrawerProps) => {
  const { nearByStoreListDataForFindStores } = useStore()
  const { isOpen, onClose, finalFocus } = props
  const { formatMessage } = useFormat({ name: 'common' })
  const [showNoResults, setshowNoResults] = useState(false)
  const [isStoreSearch, setIsStoreSearch] = useState(false)
  const [locationCoordinate, setlocationCoordinate] = useState('')

  const setDataOnClickOfFindStores = (data) => {
    setlocationCoordinate(data.addressLocation)
    setshowNoResults(data.showNoResults)
    setIsStoreSearch(data.isStoreSearch)
  }

  return (
    <Drawer finalFocusRef={finalFocus} isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent maxW={{ base: 375, md: 550 }}>
        <DrawerHeader p={'12px 36px'}>
          <Center h={'24px'} fontSize={{ base: '1rem', md: '1.25rem' }} lineHeight={'1.5rem'}>
            <Text tabIndex={0} textStyle={'heading-desktop-100'}>
              {formatMessage({ id: 'mystore.title' })}
            </Text>
          </Center>
          <DrawerCloseButton size={'sm'} fontSize={'sm'} mt={'2px'} />
        </DrawerHeader>
        <Divider />
        <Container p={'24px 24px 12px'} borderBottom={'1px solid #E2E2E2'}>
          <Box fontSize={14}>
            <StoreSearch shippingType="" setDataOnClickOfFindStores={setDataOnClickOfFindStores}></StoreSearch>
            <Flex fontSize={'12px'} tabIndex={0}>
              {nearByStoreListDataForFindStores?.length > 0 && (
                <>
                  <Text>{`${formatMessage({ id: 'mystore.displaying' })}`}</Text>
                  <Text color={'shading.600'} ml={1}>
                    {`${nearByStoreListDataForFindStores?.length} ${formatMessage({ id: 'mystore.stores.count' })}`}
                  </Text>
                </>
              )}
            </Flex>
          </Box>
        </Container>
        <DrawerBody p={{ base: '1rem' }} pl={{ md: '1.5rem' }} pr={{ md: '1.5rem' }}>
          <StoreList
            addressLocation={locationCoordinate}
            showNoResults={showNoResults}
            isOpen={isOpen}
            onClose={onClose}
            storeHeading={storeHeading}
            isStoreSearch={isStoreSearch}
            setIsStoreSearch={setIsStoreSearch}
          ></StoreList>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
